import { useQuery } from "@tanstack/vue-query";
import { AxiosError, AxiosResponse } from "axios";
import { storeToRefs } from "pinia";
import { Ref } from "vue";
import { useGameStore } from "~/stores/gameStore";
import {
  gameProvider,
  GameType,
  IDataResponse,
  IGameCategories,
  IGameCategoryWithGame,
  IImageSlider,
  resCategoryGameList,
} from "~/types/schema";

export const useGetAllProviders = () => {
  const { $api2 } = useNuxtApp();
  const { listProviders } = storeToRefs(useGameStore());
  const { data, ...rest } = useQuery<gameProvider[]>({
    queryKey: ["useGetAllProviders"],
    queryFn: async () => {
      const response = (await $api2("api/games/providers")) as AxiosResponse<
        gameProvider[]
      >;
      const providers = response.data;
      listProviders.value = providers;
      return providers;
    },
    staleTime: Infinity,
  });
  return { data: listProviders, ...rest };
};

export const useGetGameCategories = () => {
  const { $api2 } = useNuxtApp();
  const { categories } = storeToRefs(useGameStore());
  return useQuery<IGameCategories[]>({
    queryKey: ["useGetGameCategories"],
    queryFn: async () => {
      const response = (await $api2("/api/games/categories")) as AxiosResponse<
        IGameCategories[]
      >;
      const listCategories = response.data;
      categories.value = listCategories;
      return listCategories;
    },
  });
};

export const useGetListImageSliders = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<AxiosResponse<IImageSlider[]>, AxiosError, IImageSlider[]>({
    queryKey: ["useGetListImageSliders"],
    queryFn: () => $api2("/api/media/image-sliders"),
    select: (response) => response.data,
    staleTime: 60000,
  });
};

export const useGetGameCategoriesWithGames = (type: GameType) => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    AxiosResponse<IGameCategoryWithGame[]>,
    AxiosError,
    IGameCategoryWithGame[]
  >({
    queryKey: ["useGetGameCategoriesWithGames", type],
    queryFn: () =>
      $api2("/api/games", {
        params: {
          type,
        },
      }),
    select: (response) => response.data,
    staleTime: 60000,
  });
};

export const useGetPlayAgain = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    AxiosResponse<{ data: resCategoryGameList[] }>,
    AxiosError,
    resCategoryGameList[]
  >({
    queryKey: ["useGetPlayAgain"],
    queryFn: () =>
      $api2("/api/games/recently-played/list", {
        params: {
          page: 1,
        },
      }),
    select: (response) => _get(response, "data.data", []),
    staleTime: 30000,
  });
};

export const useGetBiggestWin = () => {
  const { $api2 } = useNuxtApp();
  return useQuery<
    AxiosResponse<resCategoryGameList[]>,
    AxiosError,
    resCategoryGameList[]
  >({
    queryKey: ["useGetBiggestWin"],
    queryFn: () => $api2("api/games/highest-win-amount/list"),
    select: (response) => response.data,
    staleTime: 30000,
  });
};
